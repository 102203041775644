/* eslint-disable custom-rules/no-deep-imports */
import { useState } from "react";

import {
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type TypedResponse,
  json,
} from "@remix-run/node";
import { Form, useActionData, useLoaderData } from "@remix-run/react";
import {
  Button,
  Card,
  FormLayout,
  Page,
  AppProvider as PolarisAppProvider,
  Text,
  TextField,
} from "@shopify/polaris";
import polarisTranslations from "@shopify/polaris/locales/en.json";

import { login } from "../../shopify.server";

import { type LoginLoader, loginErrorMessage } from "./error.server";

export const loader = async ({
  request,
}: LoaderFunctionArgs): Promise<TypedResponse<LoginLoader>> => {
  const errors = loginErrorMessage(await login(request));

  return json({ errors, polarisTranslations });
};

export const action = async ({
  request,
}: ActionFunctionArgs): Promise<
  TypedResponse<Omit<LoginLoader, "polarisTranslations">>
> => {
  const errors = loginErrorMessage(await login(request));

  return json({
    errors,
  });
};

export default function Auth(): JSX.Element {
  const loaderData = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const [shop, setShop] = useState("");
  const { errors } = actionData || loaderData;

  return (
    <PolarisAppProvider i18n={loaderData.polarisTranslations}>
      <Page>
        <Card>
          <Form method="post">
            <FormLayout>
              <Text variant="headingMd" as="h2">
                Log in
              </Text>
              <TextField
                type="text"
                name="shop"
                label="Shop domain"
                helpText="example.myshopify.com"
                value={shop}
                onChange={setShop}
                autoComplete="on"
                error={errors.shop}
              />
              <Button submit>Log in</Button>
            </FormLayout>
          </Form>
        </Card>
      </Page>
    </PolarisAppProvider>
  );
}
